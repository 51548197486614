var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"message"}},[_c('div',{staticClass:"header"},[_c('van-row',{attrs:{"gutter":"10"}},[_c('van-col',{staticClass:"header-title",attrs:{"span":"10"},on:{"click":_vm.onClickLeft}},[_c('svg-icon',{attrs:{"data_iconName":'back-default',"className":'back_icon'}}),_vm._v("星级管理 ")],1)],1),_c('van-row',{staticClass:"tabs"},_vm._l((_vm.tabs),function(item,index){return _c('van-col',{key:index,class:[item.type == _vm.query.status ? 'tab-item-active' : 'tab-item'],attrs:{"span":"8"},on:{"click":function($event){return _vm.onTabClick(item)}}},[_vm._v(_vm._s(item.text))])}),1)],1),_c('div',{staticClass:"message-container"},[_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[(_vm.list.length > 0)?_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.get_notifications},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[item.is_read ? 'issue-item-read' : 'issue-item'],on:{"click":function($event){return _vm.onIssueItemClick(item)}}},[_c('van-row',[_c('van-col',{attrs:{"span":"14"}},[_c('div',{class:[
                  item.is_read
                    ? 'issue-item-title-read van-ellipsis'
                    : 'issue-item-title van-ellipsis' ]},[_vm._v(" "+_vm._s(item.travel_company_name ? item.travel_company_name : "暂无")+" ")])]),_c('van-col',{attrs:{"span":"10"}},[_c('div',{class:[
                  item.is_read
                    ? 'issue-item-date text-read'
                    : 'issue-item-date' ]},[_c('van-rate',{attrs:{"size":12,"color":"#FFAA00","void-icon":"star","void-color":"#eee","readonly":""},model:{value:(item.star),callback:function ($$v) {_vm.$set(item, "star", $$v)},expression:"item.star"}})],1)])],1),(item.status == 'approving')?_c('div',{class:[
              item.status !== 'approving' ? 'dot background-read' : 'dot' ]}):_vm._e()],1)}),0):_vm._e(),(_vm.list.length == 0 && _vm.loading == false)?_c('van-empty',{attrs:{"description":"暂无数据"}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }